import { defineStore } from 'pinia'
import AuthService from '@/services'

export const useStore = defineStore('mfa', {
	state: () => {
		return {
			redirectUrl: '' as string,
			reRender: true as boolean,
			resent: false as boolean,
			resending: false as boolean
		}
	},
	actions: {
		setRedirectUrl(redirectUrl: string) {
			this.redirectUrl = redirectUrl
		},
		isRendered(value: boolean) {
			this.reRender = value
		},
		async resend() {
			try {
				this.resending = true
				await AuthService.resendMfaAction()
				this.resent = true
			} finally {
				this.resending = false
			}
		}
	}
})
