import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import Cookies from 'js-cookie'
import SignInPage from './pages/SignIn.vue'
import SignUpPage from './pages/SignUp.vue'
import ChangePasswordPage from './pages/ChangePassword.vue'
import ForgotPasswordPage from './pages/ForgotPassword.vue'
import GeneratePasswordPage from './pages/GeneratePassword.vue'
import ConsentGrantPage from './pages/DatagateConsent.vue'
import ValidateMFAPage from './pages/ValidateMFA.vue'
import SetupMFAPage from './pages/SetupMFA.vue'

Vue.use(VueRouter)

function extractRedirectUrl(route: Route) {
	const url = route.query.redirect_url + route.hash
	let redirectUrl = url

	try {
		redirectUrl = decodeURIComponent(url)
		redirectUrl = new URL(redirectUrl).href
	} catch (e) {
		redirectUrl = ''
	}

	return redirectUrl
}

const routes: Array<RouteConfig> = [
	{
		path: '/login-page',
		name: 'signin',
		/* @ts-ignore */
		component: SignInPage,
		props: (route: Route) => ({
			redirectUrl: extractRedirectUrl(route),
			showExpiredMessage: route.query.show_expired_message
		}),
		alias: '/',
		meta: {
			title: {
				id: 'Log in',
				en: 'Log in'
			}
		}
	},
	{
		path: '/lupa-kata-sandi',
		name: 'forgot_password',
		component: ForgotPasswordPage,
		meta: {
			title: {
				id: 'Lupa kata sandi',
				en: 'Forgot password'
			}
		}
	},
	{
		path: '/mfa',
		name: 'mfa',
		/* @ts-ignore */
		component: ValidateMFAPage,
		meta: {
			title: {
				id: 'Autentikasi multifaktor',
				en: 'Multifactor authentication'
			}
		}
	},
	{
		path: '/mfa-setup',
		name: 'mfa-setup',
		/* @ts-ignore */
		component: SetupMFAPage,
		meta: {
			title: {
				id: 'Setup Autentikasi multifaktor',
				en: 'Setup Multifactor authentication'
			}
		}
	},
	{
		path: '/daftar',
		name: 'signup',
		/* @ts-ignore */
		component: SignUpPage,
		props: (route: Route) => ({ redirectUrl: extractRedirectUrl(route) }),
		meta: {
			title: {
				id: 'Mulai sekarang',
				en: 'Get started'
			}
		}
	},
	{
		path: '/ganti-kata-sandi',
		name: 'change_password',
		component: ChangePasswordPage,
		props: (route) => ({ token: route.query.token }),
		meta: {
			title: {
				id: 'Ganti kata sandi',
				en: 'Change password'
			}
		}
	},
	{
		path: '/buat-kata-sandi',
		name: 'generate_password',
		component: GeneratePasswordPage,
		props: (route) => ({ userId: route.query.userId }),
		meta: {
			title: {
				id: 'Buat kata sandi',
				en: 'Generate password'
			}
		}
	},
	{
		path: '/consent-grant/:consentCode',
		name: 'consent_grant',
		component: ConsentGrantPage,
		props: (route) => ({ consentCode: route.params.consentCode }),
		meta: {
			title: {
				id: 'Pemberian Izin',
				en: 'Consent Grant'
			}
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, _, next) => {
	const lang = (Cookies.get('LANG') as string) || 'id'
	const title = to?.meta?.title[lang]

	document.title = `${title} - OnlinePajak`

	next()
})

export default router
