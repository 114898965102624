
import Vue from 'vue'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
	props: {
		type: {
			type: String,
			default: 'signin'
		}
	},
	computed: {
		titles(): Record<string, TranslateResult> {
			return {
				signin: this.$t('title.signin'),
				signup: this.$t('title.signup'),
				mfa: this.$t('title.mfa'),
				forgotPassword: this.$t('title.forgot-password'),
				changePassword: this.$t('title.change-password'),
				generatePassword: this.$t('title.generate-password')
			}
		}
	}
})
