import apiUtil from '@/utils/api'
import { domainBuilder } from '@/utils/domainBuilder'
import {
	User,
	RequestResetPassword,
	ResetPasswordPayload,
	RequestGeneratePassword,
	RequestMFA
} from '@/models'

const authApiUrl = `${domainBuilder()}/auth/api`
const consentApiUrl = `${domainBuilder()}/auth/api/consent/grant/`

export default {
	getCurrentUser() {
		return apiUtil.get(`${authApiUrl}/user`)
	},
	async signInAction(payload: User) {
		return await apiUtil.post(`${authApiUrl}/login`, payload)
	},
	async getMfaDetailsAction() {
		return await apiUtil.get(`${authApiUrl}/mfa`)
	},
	async validateMfaAction(payload: RequestMFA) {
		return await apiUtil.post(`${authApiUrl}/mfa`, payload)
	},
	async resendMfaAction() {
		return await apiUtil.post(`${authApiUrl}/mfa`, { resend: 'true' })
	},
	async setupMFAAction(payload: { phone: string }) {
		return await apiUtil.post(`${authApiUrl}/mfa/setup`, payload)
	},
	async activateMFAAction(payload: { mfaId: string; mfa: string }) {
		return await apiUtil.post(`${authApiUrl}/mfa/activate`, payload)
	},
	async signUpAction(payload: User) {
		return await apiUtil.post(`${authApiUrl}/register`, payload)
	},
	async resetPasswordAction(payload: RequestResetPassword) {
		const { message } = await apiUtil.post(`${authApiUrl}/reset_password`, payload)
		return message
	},
	async checkTokenRequest(token: string) {
		try {
			const { valid } = await apiUtil.get(`${authApiUrl}/reset_password/${token}`)
			return valid
		} catch (err) {
			return err.response.data.valid || false
		}
	},
	async resetPasswordByTokenAction(payload: ResetPasswordPayload) {
		return await apiUtil.post(`${authApiUrl}/reset_password_by_token`, payload)
	},
	async getGuestUserDetails(userId: string) {
		try {
			const { data } = await apiUtil.get(`${authApiUrl}/get_guest_details/${userId}`)
			return data.email
		} catch (err) {
			return ''
		}
	},
	async savePasswordAndLogin(payload: { userId: string } & RequestGeneratePassword) {
		try {
			const { data } = await apiUtil.post(
				`${authApiUrl}/generate_password_guest_user`,
				payload
			)
			return data.redirectUrl
		} catch (err) {
			console.error(err)
			return null
		}
	},
	async consentGrantRequest(consentCode: string) {
		try {
			const response = await apiUtil.post(consentApiUrl + consentCode, {})
			return { message: response.data, status: true }
		} catch (err) {
			const { data } = err.response
			return { message: data.data, status: false }
		}
	}
}
