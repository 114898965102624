import _Vue from 'vue'

export type Context = 'signup' | 'signin' | 'use' | undefined

export type ButtonText = 'continue_with' | 'signup_with'

export type GoogleIdConfiguration = Record<string, unknown>

export type GoogleIdentityButtonOptions = {
	clientId: string
	baseUrl: string
	redirectUrl: string
	source: string
	companyNpwp: string
	googleContext: Context
}

const languageLocales: Record<string, string> = {
	en: 'en_US',
	id: 'id_ID'
}

const buttonOptions = (locale: string): GoogleIdConfiguration => ({
	type: 'standard',
	theme: 'outline',
	size: 'large',
	shape: 'pill',
	logo_alignment: 'left',
	text: 'continue_with',
	width: '360px',
	locale: languageLocales[locale]
})

const WCButtonOptions = (locale: string, buttonText: ButtonText): GoogleIdConfiguration => ({
	type: 'standard',
	theme: 'outline',
	size: 'large',
	shape: 'pill',
	logo_alignment: 'center',
	text: buttonText,
	width: '360',
	locale: languageLocales[locale]
})

class GoogleIdentityButton implements GoogleIdentityButton {
	private _isInstalled = false
	private _isButtonCreated = false
	private _options: GoogleIdentityButtonOptions
	source = 'platform_auth'
	companyNpwp = ''
	redirectUrl = ''
	googleContext: Context = undefined
	constructor(options: GoogleIdentityButtonOptions) {
		this._options = options
	}

	initialize(): void {
		const context: Context = this._options.googleContext
			? this._options.googleContext
			: this.googleContext

		window.google.accounts.id.initialize({
			client_id: this._options.clientId,
			context: context,
			ux_mode: 'redirect',
            state: this.getState(),
            login_uri: this.getLoginUri(),
            enable_redirect_uri_validation: true,
		})
		this._isInstalled = true
	}

	reInitialize(): void {
		this._isInstalled = false
		this.initialize()
	}

	setRedirectUrl(url: string): void {
		this.redirectUrl = url
	}

    getState() {
        const searchParams = new URLSearchParams()
        if (this.redirectUrl !== '') {
            searchParams.append('redirectUrl', this.redirectUrl)
        }
        if (this.source !== '') {
            searchParams.append('source', this.source)
        }
        if (this.companyNpwp !== '') {
            searchParams.append('companyNpwp', this.companyNpwp)
        }

        return searchParams.toString()
    }

    getLoginUri(): string {
        const url = new URL(`${this._options.baseUrl}/auth/login-with-google`)
        return url.toString()
    }

	setSource(val: string): void {
		this.source = val
	}

	setCompanyNpwp(npwp: string): void {
		this.companyNpwp = npwp
	}

	get installed(): boolean {
		return this._isInstalled
	}

	get buttonCreated(): boolean {
		return this._isButtonCreated
	}

	createButton(el: HTMLElement, locale: string): void {
		window.google.accounts.id.renderButton(el, buttonOptions(locale))
		this._isButtonCreated = true
	}

	createWCButton(
		el: HTMLElement,
		locale: string,
		buttonText: ButtonText = 'continue_with'
	): void {
		window.google.accounts.id.renderButton(el, WCButtonOptions(locale, buttonText))
		this._isButtonCreated = true
	}

	setGoogleContext(context: Context): void {
		this.googleContext = context
	}
}

function install(Vue: typeof _Vue, _options: GoogleIdentityButtonOptions) {
	const gsiInstance = new GoogleIdentityButton(_options)

	if (gsiInstance.installed) return

	gsiInstance.initialize()

	Vue.prototype.$gsi = gsiInstance
}

export default {
	install
}
