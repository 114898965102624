
import { defineComponent } from 'vue'
import { useStore } from '@/store/mfa'
import AuthService from '@/services'
import PageTitle from '@/components/PageTitle.vue'
import TextField from '@/components/TextField.vue'
import PrimaryButton from '@/components/PrimaryButton.vue'
import AlertBanner from '@/components/AlertBanner.vue'
import { UI_DELAY } from '@/constants'
import { AxiosError } from 'axios'

const delay = () => new Promise((resolve) => setTimeout(resolve, UI_DELAY))

export default defineComponent({
	components: {
		PageTitle,
		TextField,
		PrimaryButton,
		AlertBanner
	},
	props: {
		isWebComponent: {
			type: Boolean,
			default: false
		},
		redirectUrl: {
			type: String,
			default: ''
		},
		showExpiredMessage: {
			type: String,
			default: null
		},
		lang: {
			type: String,
			default: 'id'
		}
	},
	setup() {
		const store = useStore()
		return { store }
	},
	data() {
		return {
			attempt: 0,
			form: {
				phone: '',
				mfa: '',
				mfaId: ''
			},
			errors: {
				mfa: '',
				phone: ''
			},
			isLoading: false,
			apiErrorMessage: ''
		}
	},
	computed: {
		isApiError(): boolean {
			return !!this.apiErrorMessage
		},
		isErrorMfa(): boolean {
			return !!this.errors.mfa
		},
		isErrorPhone(): boolean {
			return !!this.errors.phone
		},
		isRendered(): boolean {
			return this.store.reRender
		}
	},
	watch: {
		'$i18n.locale': {
			async handler() {
				this.store.isRendered(false)
				await delay()
				this.$forceUpdate()
				this.store.isRendered(true)
			},
			immediate: true,
			deep: true
		}
	},

	async mounted() {
		try {
			this.isLoading = true
			const user = await AuthService.getCurrentUser().catch(() => ({}))
			this.form.phone = user.phone
		} finally {
			this.isLoading = false
		}
	},

	methods: {
		setMfa(val: string) {
			this.form.mfa = val
			this.validateForm()
		},

		setPhone(val: string) {
			this.form.phone = val
			this.validateForm()
		},

		async sendMFA() {
			try {
				this.isLoading = true
				const res = await AuthService.setupMFAAction({ phone: this.form.phone })
				this.form.mfaId = res.data.mfaId
			} catch (err) {
				this.handleError(err)
			} finally {
				this.isLoading = false
			}
		},

		validateForm() {
			this.apiErrorMessage = ''

			if (!this.form.phone.length) this.errors.phone = 'errors.phone__required'
			else if (!/^(\+62|0)\d+$/.test(this.form.phone))
				this.errors.phone = 'errors.phone__invalid'
			else this.errors.phone = ''

			if (!this.form.mfa.length) this.errors.mfa = 'errors.mfa__required'
			else if (!/^\d{6}$/.test(this.form.mfa)) this.errors.mfa = 'errors.mfa__invalid'
			else this.errors.mfa = ''
		},

		handleError(err: AxiosError) {
			const data = err.response?.data as { errorCode: string }
			const errorCode = data?.errorCode
			if (errorCode === 'MFA_EXPIRED') {
				this.apiErrorMessage = 'errors.mfa__expired'
			} else if (errorCode === 'MFA_INVALID') {
				this.apiErrorMessage = 'errors.mfa__invalid'
			} else {
				this.apiErrorMessage = 'errors.mfa__failed'
			}
		},

		async mfaAction() {
			this.validateForm()
			this.attempt = 0

			if (!this.isErrorMfa && !this.isApiError) {
				try {
					await AuthService.activateMFAAction(this.form)
					window.alert('MFA activated. You will be redirected to login page')
					window.location.href = '/logout'
				} catch (err) {
					this.handleError(err)
				}
			}
		},

		resetErrorMessage() {
			this.apiErrorMessage = ''
		}
	}
})
